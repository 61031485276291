/**
* Created by tudou on 2020/4/27 20:00.
*/
<template>
  <div>
    <h1>Message</h1>
  </div>
</template>

<script>
export default {
  name: 'Message',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>

</style>
